import firebase from 'firebase';
import { Formik } from 'formik';
import React from 'react';
import { Card } from 'react-bootstrap';

import * as Yup from 'yup';
import PersonalDataForm from '../PersonalDataForm/BasicPersonalDataForm-component';

const isBrowser = typeof window !== 'undefined';

export function PersonalPortfolioCardForm({ userUnderReview, applicantID, formLabelsFromPrismic }) {
  const options = {
    year: 'numeric',
    // month: 'long',
    month: 'numeric',
    day: 'numeric',
  };
  const validUntil = userUnderReview?.data?.valid_until_date
    ? userUnderReview?.data?.valid_until_date
    : userUnderReview?.regValidUntil?.toDate().toLocaleString([], options) || 'N/A';

  const validFrom = userUnderReview?.data?.reg_date
    ? userUnderReview?.data?.reg_date
    : userUnderReview?.registered_on_date || 'N/A';

  const regLevel = userUnderReview?.data?.reg_level
    ? userUnderReview?.data?.reg_level
    : userUnderReview?.regLevel || 'Educator';

  const regNumber = userUnderReview?.data?.code?.text
    ? userUnderReview?.data?.code?.text
    : userUnderReview?.regNumber || 'N/A';

  const full_name = userUnderReview?.data?.name?.text
    ? userUnderReview?.data?.name?.text
    : userUnderReview?.full_name || 'N/A';

  const university = userUnderReview?.organization
    ? userUnderReview?.organization
    : userUnderReview?.data?.university || 'N/A';
  // : userUnderReview?.full_name || 'N/A'

  // /Registration details Form
  const initialValues = {
    name: full_name,
    reg_number: regNumber,
    reg_date: null,
    valid_until_date: null,
    reg_level: regLevel,
    area_expertise: userUnderReview?.data?.area_expertise || 'Engineering Educator',
    a_pdp_completed_link: userUnderReview?.data?.a_pdp_completed_link || '',
    email: userUnderReview?.email || 'N/A',
    facebook_link: userUnderReview.facebook_link || '',
    linkedin_link: userUnderReview.linkedin_link || '',
    instagram_link: userUnderReview.instagram_link || '',
    twitter_link: userUnderReview.twitter_link || '',
    personal_website_link: userUnderReview.personal_website_link || '',
    university,
    country: userUnderReview?.country || 'N/A',
    qr_code: userUnderReview?.data?.qr_code || 'not provided',
    regDataView: validFrom,
    validUntilDataView: validUntil,
  };

  const handleAddUserData = async values => {
    // this form will create/update data object in the users data, to display in portfolio card.
    const regDate = values.reg_date ? values?.reg_date?.toLocaleDateString() : '';
    const untilDate = values.valid_until_date ? values?.valid_until_date?.toLocaleDateString() : '';
    try {
      await firebase
        .firestore()
        .collection('usersCollection')
        .doc(applicantID)
        .update({
          data: {
            name: {
              text: values.name,
            },
            country: values.country,
            area: {
              text: values.area_expertise,
            },
            code: {
              text: values.reg_number,
            },
            reg_date: regDate,
            valid_until_date: untilDate,
            reg_level: values.reg_level,
            a_pdp_completed_link: values.a_pdp_completed_link,
            email: values.email,
            facebook_link: values.facebook_link || '',
            linkedin_link: values.linkedin_link || '',
            instagram_link: values.instagram_link || '',
            twitter_link: values.twitter_link || '',
            personal_website_link: values.personal_website_link || '',
            university: values.university,
            qr_code: values.qr_code,
          },
          email: values.email,
          facebook_link: values.facebook_link || '',
          linkedin_link: values.linkedin_link || '',
          instagram_link: values.instagram_link || '',
          twitter_link: values.twitter_link || '',
          personal_website_link: values.personal_website_link || '',
          country: values.country,
        });
      alert('Success!');
    } catch (error) {
      console.log(error);
    }
  };
  let firebaseAuth = {};

  if (isBrowser) {
    firebaseAuth = firebase.auth();
  }
  return (
    <Card style={{ width: '100%' }} bg="light">
      <Card.Header> Registration Details Form</Card.Header>
      <Card.Body>
        {/* After Registering Form */}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          isSubmitting
          validationSchema={Yup.object().shape({
            reg_number: Yup.number(),
            reg_level: Yup.string(),
          })}
          onSubmit={handleAddUserData}
        >
          {props => (
            <PersonalDataForm
              submitButtonLabel="SUBMIT"
              className="professional-portfolio"
              firebaseAuth={firebaseAuth}
              mainFormVars={formLabelsFromPrismic}
              {...props}
            />
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}
