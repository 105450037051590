/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import firebase from 'firebase';
import { Formik } from 'formik';
import { graphql, Link, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Container, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { defaultLanguage } from '../../prismic-configuration';
import { linkResolver } from '../utils/LinkResolver';

import countriesData from '../constants/countries.json';

// components
import AfterRegisteringForm from '../components/Form-components/PersonalDataForm/AfterRegisteringForm';
import BasicPersonalDataForm from '../components/Form-components/PersonalDataForm/BasicPersonalDataForm-component';
import useLocalStorage from '../components/Form-components/useLocalStorage';
import { Layout } from '../components/Layout';
import EducatorTab from '../components/PersonalPage-components/01EducatorTab/EducatorTab-component';
import CpAnyInstitution from '../components/PersonalPage-components/02CpAnyInstitutionTab/CpAnyInstitution-component';
import CpAccAgency from '../components/PersonalPage-components/03CpAccAgencyTab/CpAccAgency-component';
import AcademicСommitteeMember from '../components/PersonalPage-components/04AcademicСommitteeMemberTab/AcademicСommitteeMember-component';
import QaCommitteeMember from '../components/PersonalPage-components/05QaCommitteeMemberTab/QaCommitteeMember-component';
import McmTab from '../components/PersonalPage-components/06McmTab/McmTab-component';
import ExpertCommitteeMember from '../components/PersonalPage-components/07ExpertCommitteeMemberTab/ExpertCommitteeMember-component';
import SecretaryTab from '../components/PersonalPage-components/08SecretaryTab/SecretaryTab-component';
import GoverningBoard from '../components/PersonalPage-components/09GoverningBoardTab/GoverningBoard-component';
import { Seo } from '../components/Seo';
import { AfterSubmitModal } from '../commons/AfterSubmitModal';
import { useCurrentProfileData } from '../utils/useCurrentProfileData';

function PersonalDataPage({ data, location }) {
  const [showFinalSpinner, setShowFinalSpinner] = useState(true);
  const [countriesList, setCountriesList] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const [deleteButtonOn, setDeleteButtonOn] = useState(false);
  const [safetyDeleteButtonOn, setSafetyDeleteButtonOn] = useState(true);
  const isBrowser = typeof window !== 'undefined';

  const handleCloseFinalSpinner = () => setShowFinalSpinner(false);
  const handleShowFinalSpinner = () => {
    setShowFinalSpinner(true);
    setTimeout(() => {
      setShowFinalSpinner(false);
      // alert(
      //   'Something went wrong, please contact us by email info@enterprof.org',
      // )
    }, 1000);
  };

  const { currentUserProfileData } = useCurrentProfileData();

  // Fetch countries list from local json
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const countriesDataStr = JSON.stringify(countriesData);
      // @ts-ignore
      setCountriesList(countriesDataStr.split('"'));
      handleCloseFinalSpinner();
    }
    return () => {
      isMounted = false;
      setCountriesList([]);
    };
  }, []);

  // Check if in Local Storage exists item with name: personalKeyTab
  const [tabKey, setTabKey] = useLocalStorage({
    key: 'tabKey',
    value: 'profile',
  });
  const isIssueCertificate = currentUserProfileData?.currentStatus === 'Registered';

  const btnsEducator = [
    {
      label: 'My Application Form',
      link: 'MyApplicationForm',
      active: true,
    },
    {
      label: 'My ENTER Portfolio',
      link: 'MyENTERPortfolio',
      active: true,
    },
    {
      label: 'Current status : ',
      status: `${currentUserProfileData?.currentStatus}`,
      link: 'currentStatus',
      active: true,
    },
    { label: 'Issue certificate', link: 'issue', active: isIssueCertificate },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
    { label: 'Register Settings', link: '#', active: false },
  ];
  const btns_cp_any_institution = [
    {
      label: 'My Application Form',
      link: '#',
      active: false,
    },
    { label: 'Current status', link: '#', active: false },
    { label: 'Pay membership fee', link: '#', active: false },
    { label: 'Issue membership certificate', link: '#', active: false },
    { label: 'Request PDP accreditation', link: '#', active: false },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  const btns_cp_acc_agency = [
    {
      label: 'My Application Form',
      link: '#',
      active: false,
    },
    { label: 'Current status', link: '#', active: false },
    { label: 'Pay membership fee', link: '#', active: false },
    { label: 'Issue membership certificate', link: '#', active: false },
    { label: 'Submit new accredited PDP', link: '#', active: false },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  const btns_academic_committee_member = [
    { label: 'List of members', link: 'list_of_members', active: true },
    { label: 'Current status', link: '#', active: false },
    {
      label: 'Issue membership certificate',
      link: 'issue',
      active: true,
    },
    {
      label: 'Documents',
      link: 'documents',
      active: true,
    },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  const btns_qa_committee_member = [
    { label: 'List of members', link: '#', active: true },
    {
      label: 'Issue membership certificate',
      link: 'issue',
      active: true,
    },
    { label: 'Documents', link: 'documents', active: true },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  const btns_monitoring_committee_member = [
    {
      label: 'Code of ethics',
      link: 'code_of_ethics',
      active: true,
    },
    {
      label: 'Documents',
      link: 'documents',
      active: true,
    },
    {
      label: 'Issue certificate',
      link: 'issue',
      active: true,
    },
    {
      label: 'Access review process',
      link: 'access_review_process',
      active: true,
    },
    {
      label: 'To request assistance contact us via:  register@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  const btns_expert_committee_member = [
    {
      label: 'Issue membership certificate',
      link: 'issue',
      active: true,
    },
    { label: 'Documents', link: 'documents', active: true },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  const btns_sec = [
    {
      label: 'List of applications for register',
      link: 'listOfEducatorsForRegister',
      active: true,
    },
    {
      label: 'List of applications for membership (from organizations)',
      link: '#',
      active: false,
    },
    {
      label: 'List of accredited programs for approval',
      link: '#',
      active: false,
    },
    {
      label: 'List of educators to enter the register for approval',
      link: 'listOfEducatorsForApproval',
      active: true,
    },
    {
      label: 'Set Users Permissions',
      link: 'usersPermissions',
      active: true,
    },
    // { label: 'Documents', link: 'documents', active: true },
  ];
  const btns_governingBoard = [
    { label: 'Documents', link: 'documents', active: true },
    {
      label: 'To request assistance contact us via:  info@enterprof.org',
      link: '#',
      active: false,
    },
  ];
  /**
   * @type {firebase.User | null}
   */
  let user = null;
  let firebaseAuth = {};
  let firestore = {};
  // let sendEmailVerification = {}

  if (isBrowser) {
    user = firebase.auth().currentUser;

    firebaseAuth = firebase.auth();

    firestore = firebase.firestore();
    // sendEmailVerification = async () => {
    //   setEmailVerificationVisibility(!emailVerificationVisibility)
    //   setEmailVerificationSend(true)
    //   await firebase.auth().currentUser.sendEmailVerification()
    //   console.log('sent')
    // }
  }

  const handleUserDataUpdate = async (values, actions) => {
    handleShowFinalSpinner();
    setIsEditing(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setTimeout(() => {
      // navigate('/')
      actions.setSubmitting(false);
    }, 2000);

    try {
      await firestore
        .collection('usersCollection')
        .doc(currentUserProfileData.id)
        .update({
          /* 3* */ full_name: values.full_name || '',
          /* 3* */ email: values.email || '',
          /* 3* */ phone_number: values.phone_number || '',
          /* 4* */ organization: values.organization || '',
          /* 5* */ website: values.website || '',
          /* 6* */ country: values.country || '',
          /* 7* */ city: values.city || '',
          /* 8 */ street: values.street || '',
          /* 9 */ postal_code: values.postal_code || '',
          /* 10* */ pref_lang: values.pref_lang || '',
        });
    } catch (error) {
      console.log(error);
    }
  }; // Add function to change Name and/or Email in Authentication Account if where changed!

  if (!data) return null;

  const pageContent = data.prismicEnterAccountPage;
  const doc = data.prismicEnterAccountPage.data;

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  // for Educator tab
  const firstFormVars = data.prismicMcmReviewForm?.data?.body[0]?.items;
  const secondFormVars = data.prismicMcmReviewForm?.data?.body[1]?.items;
  //
  const formLabelsFromPrismic = data.prismicProfessionalPortfolioCard?.data?.body[0]?.items;

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;

  const basicFormVariablesFromPrismic = pageContent.data.body[0];

  const formWStripeVariablesFromPrismic = pageContent.data.educator_tab_body[0];

  const topMenu = data.prismicMenuLink || {};
  const pageTitle = topMenu.data.menu_links[5].link_title.text || 'Personal Area';

  if (currentUserProfileData === undefined && !user) {
    return (
      <AfterSubmitModal
        showFinalSpinner={showFinalSpinner}
        handleCloseFinalSpinner={handleCloseFinalSpinner}
      />
    );
  }

  if (currentUserProfileData === undefined) {
    return (
      <AfterRegisteringForm
        firebaseAuth={firebaseAuth}
        basicFormVariablesFromPrismic={basicFormVariablesFromPrismic}
        firestore={firestore}
      />
    );
  }

  const afterRegisteringInitialValues = currentUserProfileData || {
    full_name: user?.displayName ?? 'Could`not get users data',
    email: user?.email ?? 'Could`not get users data',
    /* 3* */ phone_number: '',
    /* 4* */ organization: '',
    /* 5* */ website: '',
    /* 6* */ country: '',
    /* 7* */ city: '',
    /* 8 */ street: '',
    /* 9 */ postal_code: '',
    /* 10* */ pref_lang: 'English',
  };

  if (currentUserProfileData) {
    return (
      // @ts-ignore
      <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
        {/* @ts-ignore */}
        <Seo title={pageTitle} lang={lang} />
        {/* @ts-ignore */}
        <Container>
          <div className="welcomeInfo">
            <h1 className="pp-title ">
              {doc.title.text} {currentUserProfileData?.full_name}!
            </h1>

            <div className="signedIn-buttons-wrapper">
              {safetyDeleteButtonOn && (
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={async () => {
                    setDeleteButtonOn(true);
                    setSafetyDeleteButtonOn(false);
                  }}
                >
                  Delete Account?
                </button>
              )}
              {deleteButtonOn && (
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={async () => {
                    // have to Check if it`s possible to delete, and if not, ask to sign out and sign in again
                    await navigate('/');
                    try {
                      await firestore
                        .collection('usersCollection')
                        .doc(currentUserProfileData?.id)
                        .delete();
                      await user?.delete();
                    } catch (err) {
                      alert('To delete your account, please Sign In again');
                      await firebaseAuth.signOut();
                    }
                  }}
                >
                  YES
                </button>
              )}
              <button
                type="button"
                className="btn btn-outline-info"
                onClick={async () => {
                  await firebaseAuth.signOut();

                  await navigate('/login');
                }}
              >
                Sign-out
              </button>
              {/* {!user?.emailVerified &&
                !emailVerificationSend &&
                emailVerificationVisibility && (
                  <span>
                    Please click{' '}
                    <a
                      className="link-danger cursor-pointer"
                      onClick={sendEmailVerification}
                    >
                      {' '}
                      here
                    </a>{' '}
                    to verify your email!{' '}
                  </span>
                )} */}
              {/* {!user?.emailVerified &&
                emailVerificationSend &&
                !emailVerificationVisibility && (
                  <span>Confirmation is send. Please check your email!</span>
                )} */}
              {/* <span>
                Click{' '}
                <a className="link-danger cursor-pointer" onClick={() => {}}>
                  {' '}
                  here
                </a>{' '}
                to change your password{' '}
              </span> */}
            </div>
          </div>

          <Tabs
            id="controlled-tab"
            activeKey={tabKey}
            onSelect={k => setTabKey(k)}
            className="profileTab mb-3"
          >
            {/* Profile Tab */}
            <Tab className="profileTabContent" eventKey="profile" title={doc.profile_label.text}>
              {/* Editing From */}
              {tabKey === 'profile' && isEditing && (
                <Formik
                  enableReinitialize
                  initialValues={afterRegisteringInitialValues}
                  isSubmitting
                  validationSchema={Yup.object().shape({
                    /* 2* */ organization: Yup.string().required('Required'),
                    /* 2* */ website: Yup.string().required('Required'),
                    /* 4* */ email: Yup.string()
                      .email('Invalid email address`')
                      .required('Required'),
                    /* 5* */ phone_number: Yup.number().typeError('Must be a number'),
                    /* 6* */ country: Yup.string()
                      .required('Required')
                      .oneOf(countriesList, 'Please enter valid country name in English'),
                    /* 7* */ city: Yup.string().required('Required'),
                    /* 8 */ street: Yup.string().required('Required'),
                    /* 9* */ postal_code: Yup.string().required('Required'),
                    /* 10* */ pref_lang: Yup.string().required('Required'),
                    // /*11**/ acceptedTerms: Yup.boolean()
                    //   .required('Required')
                    //   .oneOf(
                    //     [true],
                    //     'You have to accept the terms and conditions.',
                    //   ),
                  })}
                  onSubmit={handleUserDataUpdate}
                >
                  {props => (
                    <BasicPersonalDataForm
                      cancelButtonLabel="CANCEL"
                      submitButtonLabel="SAVE"
                      mainFormVars={basicFormVariablesFromPrismic.items}
                      cancelAction={() => {
                        setIsEditing(false);
                      }}
                      // saveForm={handleUpdateForm}
                      {...props}
                    />
                  )}
                </Formik>
              )}
              {tabKey === 'profile' && !isEditing && (
                <div>
                  <ul className="profileStatic">
                    {currentUserProfileData?.full_name && (
                      <li>
                        {basicFormVariablesFromPrismic.items[0].title}:{' '}
                        <span className="bold">{currentUserProfileData?.full_name}</span>
                      </li>
                    )}
                    {currentUserProfileData?.email && (
                      <li>
                        {basicFormVariablesFromPrismic.items[1].title}:{' '}
                        <span className="bold">{currentUserProfileData?.email}</span>
                      </li>
                    )}
                    {currentUserProfileData?.phone_number && (
                      <li>
                        {basicFormVariablesFromPrismic.items[2].title}:{' '}
                        <span className="bold">{currentUserProfileData?.phone_number}</span>
                      </li>
                    )}
                    {currentUserProfileData?.organization && (
                      <li>
                        {basicFormVariablesFromPrismic.items[3].title}:{' '}
                        <span className="bold">{currentUserProfileData?.organization}</span>
                      </li>
                    )}
                    {currentUserProfileData?.website && (
                      <li>
                        {basicFormVariablesFromPrismic.items[4].title}:{' '}
                        <span className="bold">{currentUserProfileData?.website}</span>
                      </li>
                    )}
                    {currentUserProfileData?.country && (
                      <li>
                        {basicFormVariablesFromPrismic.items[5].title}:{' '}
                        <span className="bold">{currentUserProfileData?.country}</span>
                      </li>
                    )}
                    {currentUserProfileData?.city && (
                      <li>
                        {basicFormVariablesFromPrismic.items[6].title}:{' '}
                        <span className="bold">{currentUserProfileData?.city}</span>
                      </li>
                    )}
                    {currentUserProfileData?.street && (
                      <li>
                        {basicFormVariablesFromPrismic.items[7].title}:{' '}
                        <span className="bold">{currentUserProfileData?.street}</span>
                      </li>
                    )}
                    {currentUserProfileData?.postal_code && (
                      <li>
                        {basicFormVariablesFromPrismic.items[8].title}:{' '}
                        <span className="bold">{currentUserProfileData?.postal_code}</span>
                      </li>
                    )}
                    {currentUserProfileData?.pref_lang && (
                      <li>
                        {basicFormVariablesFromPrismic.items[9].title}:{' '}
                        <span className="bold">{currentUserProfileData?.pref_lang}</span>
                      </li>
                    )}
                  </ul>
                  <span
                    role="button"
                    tabIndex={0}
                    className="btn btn-outline-info px-4"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        setIsEditing(true);
                      }
                    }}
                  >
                    Edit
                  </span>
                </div>
              )}
            </Tab>
            {/* Educator Tab */}
            {currentUserProfileData.educator && (
              <Tab eventKey="educator" title={doc.educator_tab_label.text}>
                {tabKey === 'educator' && (
                  <EducatorTab
                    currentUserProfileData={currentUserProfileData}
                    firstFormVars={firstFormVars}
                    secondFormVars={secondFormVars}
                    lang={lang}
                    usersDataObject={currentUserProfileData}
                    userId={currentUserProfileData.id}
                    firestore={firestore}
                    buttons={btnsEducator}
                    formWStripeVariables={formWStripeVariablesFromPrismic}
                    location={location}
                    full_name={currentUserProfileData.full_name}
                    email={currentUserProfileData.email}
                    country={currentUserProfileData.country}
                  />
                )}
              </Tab>
            )}
            {/* Contact Person Institution */}
            {currentUserProfileData.cp_any_institution && (
              <Tab eventKey="cp_any_institution" title={doc.cpai_nai_tab_label.text}>
                {tabKey === 'cp_any_institution' && (
                  <CpAnyInstitution lang={lang} buttons={btns_cp_any_institution} />
                )}
              </Tab>
            )}
            {/* Contact Person Accreditation Agency */}
            {currentUserProfileData.cp_acc_agency && (
              <Tab eventKey="cp_acc_agency" title={doc.cpaa_tab_label.text}>
                {tabKey === 'cp_acc_agency' && (
                  <CpAccAgency lang={lang} buttons={btns_cp_acc_agency} />
                )}
              </Tab>
            )}
            {/* Academic Committee member  */}
            {currentUserProfileData.academic_committee_member && (
              <Tab eventKey="academic_committee_member" title={doc.acm_tab_label.text}>
                {tabKey === 'academic_committee_member' && (
                  <AcademicСommitteeMember
                    lang={lang}
                    usersDataObject={currentUserProfileData}
                    buttons={btns_academic_committee_member}
                    full_name={currentUserProfileData.full_name}
                  />
                )}
              </Tab>
            )}
            {/* QA Committee member */}
            {currentUserProfileData.qa_committee_member && (
              <Tab eventKey="qa_committee_member" title={doc.qacm_tab_label.text}>
                {tabKey === 'qa_committee_member' && (
                  <QaCommitteeMember
                    lang={lang}
                    usersDataObject={currentUserProfileData}
                    buttons={btns_qa_committee_member}
                    full_name={currentUserProfileData.full_name}
                  />
                )}
              </Tab>
            )}
            {/* Monitoring Committee member */}
            {currentUserProfileData.monitoring_committee_member && (
              <Tab eventKey="monitoring_committee_member" title={doc.mcm_tab_label.text}>
                {tabKey === 'monitoring_committee_member' && (
                  <McmTab
                    lang={lang}
                    usersDataObject={currentUserProfileData}
                    userId={currentUserProfileData.id}
                    firestore={firestore}
                    buttons={btns_monitoring_committee_member}
                    formWStripeVariables={formWStripeVariablesFromPrismic}
                    location={location}
                    full_name={currentUserProfileData.full_name}
                    email={currentUserProfileData.email}
                    country={currentUserProfileData.country}
                  />
                )}
              </Tab>
            )}
            {/* Expert Committee member */}
            {currentUserProfileData.expert_committee_member && (
              <Tab eventKey="expert_committee_member" title={doc.ecm_tab_label.text}>
                {tabKey === 'expert_committee_member' && (
                  <ExpertCommitteeMember
                    usersDataObject={currentUserProfileData}
                    buttons={btns_expert_committee_member}
                    full_name={currentUserProfileData.full_name}
                  />
                )}
              </Tab>
            )}
            {/* Governing Board */}
            {currentUserProfileData.president && (
              <Tab eventKey="president" title={doc.governing_board_access_tab_label.text}>
                {tabKey === 'president' && <GoverningBoard buttons={btns_governingBoard} />}
              </Tab>
            )}
            {/* Admin tab */}
            {currentUserProfileData.sec && (
              <Tab eventKey="admin" title={doc.secretary_tab_label.text}>
                {tabKey === 'admin' && (
                  <SecretaryTab
                    formLabelsFromPrismic={formLabelsFromPrismic}
                    firstFormVars={firstFormVars}
                    secondFormVars={secondFormVars}
                    defaultLanguage={defaultLanguage}
                    lang={lang}
                    usersDataObject={currentUserProfileData}
                    userId={currentUserProfileData.id}
                    userUid={currentUserProfileData.uid}
                    firestore={firestore}
                    buttons={btns_sec}
                    formWStripeVariables={formWStripeVariablesFromPrismic}
                    location={location}
                    full_name={currentUserProfileData.full_name}
                    email={currentUserProfileData.email}
                    country={currentUserProfileData.country}
                    firebaseAuth={firebaseAuth}
                  />
                )}
              </Tab>
            )}
          </Tabs>
        </Container>
        <AfterSubmitModal
          showFinalSpinner={showFinalSpinner}
          handleCloseFinalSpinner={handleCloseFinalSpinner}
        />
      </Layout>
    );
  }
  return (
    <section>
      {/* @ts-ignore  */}
      <Container>
        <span className="text-center mt-5 p-5">Hold on...</span>
        <p className="justify-content-center text-center">
          {/* @ts-ignore  */}
          <Link to={`${localLinkResolver}/`}>
            <span>Go Home</span>
          </Link>
        </p>
        <AfterSubmitModal
          showFinalSpinner={showFinalSpinner}
          handleCloseFinalSpinner={handleCloseFinalSpinner}
        />
      </Container>
    </section>
  );
}

export const query = graphql`
  query prismicEnterAccountPage($id: String, $lang: String) {
    prismicEnterAccountPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        secretary_tab_label {
          text
        }
        qacm_tab_label {
          text
        }
        mcm_tab_label {
          text
        }
        governing_board_access_tab_label {
          text
        }
        educator_tab_label {
          text
        }
        ecm_tab_label {
          text
        }
        cpai_nai_tab_label {
          text
        }
        cpaa_tab_label {
          text
        }
        acm_tab_label {
          text
        }
        profile_label {
          text
        }
        body {
          ... on PrismicEnterAccountPageDataBodyForm {
            id
            items {
              description
              field_type
              form_type
              placeholder
              name
              radio_options
              required
              title
            }
            primary {
              previous__button_label
              next__button_label
            }
          }
        }
        educator_tab_body {
          ... on PrismicEnterAccountPageDataEducatorTabBodyForm {
            id
            primary {
              previous__button_label
              proceed_to_the_payment__button_label
              educator_form_with_stripe_title {
                text
              }
            }
            items {
              title
              required
              radio_options
              field_type
              description
              placeholder
              name
              form_type
            }
          }
        }
      }
    }
    prismicMcmReviewForm(lang: { eq: $lang }) {
      data {
        applicants_portfolio_review
        enter_register_review
        mcm_form_title {
          text
        }
        body {
          ... on PrismicMcmReviewFormDataBodyForm {
            items {
              description
              field_type
              form_type
              name
              placeholder
              required
              radio_options
              title
            }
          }
        }
      }
    }
    prismicProfessionalPortfolioCard {
      data {
        card_labels {
          accredited_pdp_completed_label
          area_of_expertise_label
          country_label
          email_label
          registration_date_label
          registration_level_label
          registration_number_label
          university_label
          valid_until_date_label
        }
        title {
          text
        }
        body {
          ... on PrismicProfessionalPortfolioCardDataBodyForm {
            id
            items {
              description
              field_type
              form_type
              name
              placeholder
              radio_options
              required
              title
            }
          }
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(PersonalDataPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || '',
    linkResolver,
  },
]);
