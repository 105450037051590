import React from 'react';

export const DeclarePermissions = ({ firestore, currentUserProfileData, isSecretary }) => {
  const toggleEmail = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isEmailVisible: !currentUserProfileData.isEmailVisible,
      });

      currentUserProfileData.isEmailVisible
        ? alert('E-mail is not visible for the Enter Register')
        : alert('E-mail is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  const toggleUniversity = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isUniversityVisible: !currentUserProfileData.isUniversityVisible,
      });

      currentUserProfileData.isUniversityVisible
        ? alert('University is not visible for the Enter Register')
        : alert('University is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  const togglePersonalWebsite = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isPersonalWebsiteVisible: !currentUserProfileData.isPersonalWebsiteVisible,
      });

      currentUserProfileData.isPersonalWebsiteVisible
        ? alert('Personal Website is not visible for the Enter Register')
        : alert('Personal Website is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  const toggleFacebookLink = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isFacebookLinkVisible: !currentUserProfileData.isFacebookLinkVisible,
      });

      currentUserProfileData.isFacebookLinkVisible
        ? alert('Facebook Link is not visible for the Enter Register')
        : alert('Facebook Link is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  const toggleLinkedInLink = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isLinkedinLinkVisible: !currentUserProfileData.isLinkedinLinkVisible,
      });

      currentUserProfileData.isLinkedinLinkVisible
        ? alert('LinkedIn Link is not visible for the Enter Register')
        : alert('LinkedIn Link is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  const toggleInstagramLink = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isInstagramLinkVisible: !currentUserProfileData.isInstagramLinkVisible,
      });

      currentUserProfileData.isInstagramLinkVisible
        ? alert('Instagram Link is not visible for the Enter Register')
        : alert('Instagram Link is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  const toggleTwitterLink = async () => {
    try {
      await firestore.collection('usersCollection').doc(currentUserProfileData.id).update({
        isTwitterLinkVisible: !currentUserProfileData.isTwitterLinkVisible,
      });

      currentUserProfileData.isTwitterLinkVisible
        ? alert('Twitter Link is not visible for the Enter Register')
        : alert('Twitter Link is now visible for the Enter Register');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {!isSecretary && (
        <div>
          Please declare your permission to make publicly available in the ENTER Register the
          following data.
        </div>
      )}
      <div
        style={{ width: '100% important' }}
        className="d-flex w-100 p-2 flex-wrap flex-row justify-content-between shadow"
      >
        {/* <span>E-mail</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          {' '}
          <span>E-mail</span>
          <button
            style={{ width: '120px !important' }}
            onClick={toggleEmail}
            className={`btn ${
              currentUserProfileData.isEmailVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isEmailVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
        {/* <span>University</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          <span>University</span>

          <button
            style={{ width: '120px !important' }}
            onClick={toggleUniversity}
            className={`btn ${
              currentUserProfileData.isUniversityVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isUniversityVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
        {/* <span>PersonalWebsite</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          <span>Personal Website</span>

          <button
            style={{ width: '120px !important' }}
            onClick={togglePersonalWebsite}
            className={`btn ${
              currentUserProfileData.isPersonalWebsiteVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isPersonalWebsiteVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
        {/* <span>facebook_link</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          {' '}
          <span>Facebook Link</span>
          <button
            style={{ width: '120px !important' }}
            onClick={toggleFacebookLink}
            className={`btn ${
              currentUserProfileData.isFacebookLinkVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isFacebookLinkVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
        {/* <span>linkedin_link</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          {' '}
          <span>LinkedIn Link</span>
          <button
            style={{ width: '120px !important' }}
            onClick={toggleLinkedInLink}
            className={`btn ${
              currentUserProfileData.isLinkedinLinkVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isLinkedinLinkVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
        {/* <span>instagram_link</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          <span>Instagram Link</span>
          <button
            style={{ width: '120px !important' }}
            onClick={toggleInstagramLink}
            className={`btn ${
              currentUserProfileData.isInstagramLinkVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isInstagramLinkVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
        {/* <span>twitter_link</span> */}
        <div
          style={{ minWidth: '150px' }}
          className="d-flex flex-column gap-2 w-25 justify-content-center align-items-center p-1"
        >
          <span>Twitter Link</span>

          <button
            style={{ width: '120px !important' }}
            onClick={toggleTwitterLink}
            className={`btn ${
              currentUserProfileData.isTwitterLinkVisible ? 'btn-success' : 'btn-secondary'
            }`}
          >
            {currentUserProfileData.isTwitterLinkVisible ? 'Visible' : 'Hidden'}
          </button>
        </div>
      </div>
    </>
  );
};
