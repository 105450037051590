import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import qr_code_placeholder from '../images/qrcode_enterprof.org.png';
import { committeeQrCodeImage } from './getCommitteeQrCodeImage';
import { committeeYearSince } from './getCommitteeYearSince';

const usePDFGenerator = props => {
  const [imageUrlBase64, setImageUrlBase64] = useState('');
  const committeeName = props.committeeName || null;
  const currentUserProfileData = props.currentUserProfileData || null;

  useEffect(() => {
    const getImageToBase64 = async () => {
      const imageUrl =
        props.type === 'committee'
          ? committeeQrCodeImage(props.committeeName)
          : props.currentUserProfileData?.data?.qr_code;
      try {
        const result = await axios.get('/api/getImage', {
          params: {
            imageUrl,
          },
        });
        setImageUrlBase64(result.data);
      } catch (error) {
        console.log('error', error);
      }
    };
    setTimeout(() => {
      if (
        props.currentUserProfileData?.currentStatus === 'Registered' ||
        props.type === 'committee'
      ) {
        getImageToBase64();
      }
      // ;(props.currentUserProfileData?.currentStatus === 'Registered' ||
      //   props.type === 'committee') &&
    }, 1000);
    return () => {};
  }, [
    props.currentUserProfileData?.data?.qr_code,
    props.currentUserProfileData?.currentStatus,
    props.committeeName,
    props.type,
  ]);
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      position: 'relative',
    },
    titleSection: {
      width: '90%',
      height: '95px',
      marginBottom: 10,
      position: 'relative',
      top: '345px',
      display: 'flex',
      justifyContent: 'center',
      paddingHorizontal: 40,
      alignItems: 'center',
      margin: '0 auto',
    },
    dataSection: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 auto',
      width: '68%',
      height: '160px',
      marginBottom: 20,
      position: 'relative',
      top: '405px',
    },
    issuedDateSection: {
      fontSize: '12px',
      width: '100%',
      height: '30px',
      position: 'absolute',
      bottom: '1px',
      left: '15px',
    },
    qrCodeSection: {
      width: '80px',
      height: '80px',
      position: 'absolute',
      bottom: '20px',
      right: '20px',
    },
    title: {
      width: '100%',
      textAlign: 'center',
      color: '#003491',
      fontFamily: 'Helvetica-BoldOblique',
      fontSize: 35,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    dataDetails: {
      fontSize: 18,
    },
    image: {
      width: '100%',
      height: 845,
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    qrImage: {
      width: 80,
      height: 80,
    },
  });
  const dateNow = new Date(Date.now()).toLocaleString().split(',')[0];

  const EducatorCertificate = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image src={props.bgCertificateImage || ''} style={styles.image} />
        </View>
        <View style={styles.titleSection}>
          <Text style={styles.title}>{props.full_name || 'No name provided'}</Text>
        </View>
        <View style={styles.dataSection}>
          <Text style={styles.dataDetails}>
            Registration Number: {props.currentUserProfileData?.data?.code?.text}
          </Text>
          <Text style={styles.dataDetails}>
            Registration Date: {props.currentUserProfileData?.data?.reg_date}
          </Text>
          <Text style={styles.dataDetails}>
            Valid Until: {props.currentUserProfileData?.data?.valid_until_date}
          </Text>
          <Text style={styles.dataDetails}>
            Registration Level: {props.currentUserProfileData?.data?.reg_level}
          </Text>
          <Text style={styles.dataDetails}>
            Area of Expertise: {props.currentUserProfileData?.data?.area.text}
          </Text>
        </View>
        <View style={styles.issuedDateSection}>
          <Text style={{ color: 'black' }}>Date of issue: {dateNow}</Text>
        </View>
        <View style={styles.qrCodeSection}>
          <Image
            // src={imageUrlBase64 || './hg.jpg'}
            src={
              props.currentUserProfileData?.data?.qr_code === 'N/A'
                ? qr_code_placeholder
                : imageUrlBase64 || qr_code_placeholder
            }
            style={styles.qrImage}
          />
        </View>
      </Page>
    </Document>
  );
  const stylesCom = StyleSheet.create({
    page: {
      flexDirection: 'column',
      position: 'relative',
    },
    titleSection: {
      width: '90%',
      // border: '2px solid red',
      position: 'relative',
      top: '360px',
      display: 'flex',
      justifyContent: 'center',
      paddingHorizontal: 45,
      alignItems: 'center',
      margin: '0 auto',
    },
    dataSection: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: '0 auto',
      width: '68%',
      height: '160px',
      marginBottom: 20,
      position: 'relative',
      top: '335px',
    },
    issuedDateSection: {
      fontSize: '12px',
      width: '100%',
      height: '30px',
      position: 'absolute',
      bottom: '1px',
      left: '110px',
    },
    qrCodeSection: {
      width: '80px',
      height: '80px',
      position: 'absolute',
      bottom: '10px',
      right: '80px',
    },
    title: {
      width: '100%',
      textAlign: 'center',
      color: '#00aeef',
      fontFamily: 'Helvetica',
      fontSize: 32,
      maxHeight: '75px',
      marginBottom: 5,
      // border: '2px solid red',

      // fontWeight: 'bold',
      // fontStyle: 'italic',
      textTransform: 'uppercase',
    },
    dataCertify: {
      fontSize: 22,
      width: '70%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '8px',
      letterSpacing: '1.2',
    },
    committeeName: {
      fontSize: 24,
      width: '75%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '10px',
      marginBottom: '15px',
      letterSpacing: '1.2',
      textTransform: 'uppercase',
    },
    image: {
      width: '100%',
      height: 845,
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    qrImage: {
      width: 80,
      height: 80,
    },
  });

  const CommitteeCertificate = () => (
    <Document>
      <Page size="A4" style={stylesCom.page}>
        <View>
          <Image src={props.bgCertificateImage || ''} style={stylesCom.image} />
        </View>
        <View style={stylesCom.titleSection}>
          <Text style={stylesCom.title}>{props.full_name || 'No name provided'}</Text>
          <Text style={stylesCom.dataCertify}>to certify membership</Text>
          <Text style={stylesCom.dataCertify}>in the Enter Network</Text>
          <Text style={stylesCom.committeeName}>{props.committeeName}</Text>
          <Text style={stylesCom.dataCertify}>
            since {committeeYearSince({ committeeName, currentUserProfileData })}
          </Text>
        </View>
        <View style={stylesCom.issuedDateSection}>
          <Text style={{ color: 'black' }}>Date of issue: {dateNow}</Text>
        </View>
        <View style={stylesCom.qrCodeSection}>
          <Image src={imageUrlBase64 || qr_code_placeholder} style={stylesCom.qrImage} />
        </View>
      </Page>
    </Document>
  );

  const DocumentSelector =
    props.type === 'committee' ? <CommitteeCertificate /> : <EducatorCertificate />;

  const PdfDownLink = () => {
    return (
      <PDFDownloadLink document={DocumentSelector} fileName="Certificate.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Issue Certificate')}
      </PDFDownloadLink>
    );
  };

  return { PdfDownLink, EducatorCertificate, CommitteeCertificate };
};

export default usePDFGenerator;
