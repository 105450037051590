import React, { useState, useEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

const ListOfEducatorsForApproval = ({
  userId,
  firestore,
  full_name,
  email,
  country,
  topMenu,
  activeDoc,
  formWStripeVariables,
  saveForm,
  location,
  handleClose,
  usersDataObject,
  lang,
  defaultLanguage,
  ...props
}) => {
  const [reviewingCollectionList, setReviewingCollectionList] = useState([])
  const [usersList, setUsersList] = useState([])
  const [usersUnderReview, setUsersUnderReview] = useState([])
  //fetch if exist Firebase firestore(database) data
  useEffect(() => {
    let isMounted = true
    if (isBrowser && isMounted) {
      try {
        firestore
          .collection('usersCollection')
          .orderBy('initial_registration_date', 'desc')
          .onSnapshot((snapshot) => {
            const listUsers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            setUsersList(listUsers)
            const underReview = listUsers.filter(
              (userFromList) => userFromList.under_review,
            )
            setUsersUnderReview(underReview)
          })
      } catch (error) {
        console.log(error)
      }
    }
    return () => {
      isMounted = false
    }
  }, [])
  useEffect(() => {
    let isMounted = true
    if (isBrowser && isMounted) {
      try {
        firestore.collection('reviewingCollection').onSnapshot((snapshot) => {
          const processesList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setReviewingCollectionList(processesList)
        })
      } catch (error) {
        console.log(error)
      }
    }
    return () => {
      if (isBrowser && isMounted) {
        try {
          firestore.collection('reviewingCollection').onSnapshot((snapshot) => {
            const processesList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            setReviewingCollectionList(processesList)
          })
        } catch (error) {
          console.log(error)
        }
        isMounted = false
      }
    }
  }, [])

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`

  return (
    <div>
      {usersUnderReview[0] && (
        <div>
          <h1>
            List of educators to enter the register for approval{' '}
            <span
              style={{
                fontWeight: '400',
                textAlign: 'center',
                padding: '5px auto',
                margin: '0 15px',
                overflow: 'hidden',
                width: '80px',
              }}
            >
              ({usersUnderReview.length})
            </span>
          </h1>
          <button
            className="btn btn-outline-primary"
            onClick={handleClose}
            type="button"
          >
            back
          </button>
        </div>
      )}
      {usersUnderReview.map((userToBeApproved, key) => {
        const curMcm1Data = usersList.filter(
          (userFromList) => userFromList.id === userToBeApproved.mcm1,
        )[0]
        const cureentReviewProcessDataMCM1 = reviewingCollectionList.filter(
          (revProcess) => revProcess.id === userToBeApproved.mcm1RevProcess,
        )[0]
        const curMcm2Data = usersList.filter(
          (userFromList) => userFromList.id === userToBeApproved.mcm2,
        )[0]
        const cureentReviewProcessDataMCM2 = reviewingCollectionList.filter(
          (revProcess) => revProcess.id === userToBeApproved.mcm2RevProcess,
        )[0]
        const curMcm3Data = usersList.filter(
          (userFromList) => userFromList.id === userToBeApproved.mcm3,
        )[0]
        const cureentReviewProcessDataMCM3 = reviewingCollectionList.filter(
          (revProcess) => revProcess.id === userToBeApproved.mcm3RevProcess,
        )[0]
        
        return (
          <div
            key={key}
            className="shadow border border-1 my-4 d-flex flex-row justify-content-between"
          >
            <div
              className="m-3 d-flex flex-column justify-content-between"
              style={{ flexBasis: '250px' }}
            >
              <div>
                <h3 className="p-2">{userToBeApproved?.full_name}</h3>
                <p className="p-2">ID: {userToBeApproved?.id}</p>
                {userToBeApproved?.dateAppliedForReview && (
                  <span className="p-2">
                    Date Applied For Review:{' '}
                    {userToBeApproved?.dateAppliedForReview}
                  </span>
                )}
              </div>
              <div>
                <a
                  href={`${localLinkResolver}/enter-educators/final-step-approval-proccess?mcm1RevProcess=${
                    userToBeApproved.mcm1RevProcess || ''
                  }&mcm2RevProcess=${
                    userToBeApproved.mcm2RevProcess || ''
                  }&mcm3RevProcess=${
                    userToBeApproved.mcm3RevProcess || ''
                  }&userToBeApprovedId=${userToBeApproved?.id}`}
                  className="btn btn-sm btn-outline-dark mx-2"
                  style={{ alignSelf: 'flex-end' }}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Check Review Proccess
                </a>
              </div>
            </div>

            <div className="d-flex flex-column" style={{ flexGrow: '1' }}>
              {/* curMcm1 */}
              <div
                className={`${
                  !!userToBeApproved.mcm1ReviewSubmited
                    ? 'bg-info'
                    : 'bg-warning'
                } mx-3 border rounded m-3  border rounded align-items-start p-3`}
              >
                Assigned MCM 1 Name:
                <span className="font-monospace">
                  {' '}
                  {cureentReviewProcessDataMCM1?.mcm_name
                    ? cureentReviewProcessDataMCM1?.mcm_name
                    : curMcm1Data?.full_name}
                </span>
                <br />
                Assigned MCM 1 ID:{' '}
                <span className="font-monospace">
                  {cureentReviewProcessDataMCM1?.mcmID
                    ? cureentReviewProcessDataMCM1?.mcmID
                    : curMcm1Data?.id}
                </span>
                <hr />
                <span>
                  {userToBeApproved?.mcm1RevProcess
                    ? `Review Process id  ${userToBeApproved?.mcm1RevProcess}`
                    : 'Process does not started yet'}
                </span>
              </div>
              {/* curMcm2 */}
              <div
                className={`${
                  !!userToBeApproved.mcm2ReviewSubmited
                    ? 'bg-info'
                    : 'bg-warning'
                } mx-3 border rounded m-3  border rounded align-items-start p-3`}
              >
                Assigned MCM 2 Name:
                <span className="font-monospace">
                  {' '}
                  {cureentReviewProcessDataMCM2?.mcm_name
                    ? cureentReviewProcessDataMCM2?.mcm_name
                    : curMcm2Data?.full_name}
                </span>
                <br />
                Assigned MCM 2 ID:{' '}
                <span className="font-monospace">
                  {cureentReviewProcessDataMCM2?.mcmID
                    ? cureentReviewProcessDataMCM2?.mcmID
                    : curMcm2Data?.id}
                </span>
                <hr />
                <span>
                  {userToBeApproved?.mcm2RevProcess
                    ? `Review Process id  ${userToBeApproved?.mcm2RevProcess}`
                    : 'Process does not started yet'}
                </span>
              </div>
              {/* curMcm3 */}
              <div
                className={`${
                  !!userToBeApproved.mcm3ReviewSubmited
                    ? 'bg-info'
                    : 'bg-warning'
                } mx-3 border rounded m-3  border rounded align-items-start p-3`}
              >
                Assigned MCM 3 Name:
                <span className="font-monospace">
                  {' '}
                  {cureentReviewProcessDataMCM3?.mcm_name
                    ? cureentReviewProcessDataMCM3?.mcm_name
                    : curMcm3Data?.full_name}
                </span>
                <br />
                Assigned MCM 3 ID:{' '}
                <span className="font-monospace">
                  {cureentReviewProcessDataMCM3?.mcmID
                    ? cureentReviewProcessDataMCM3?.mcmID
                    : curMcm3Data?.id}
                </span>
                <hr />
                <span>
                  {userToBeApproved?.mcm3RevProcess
                    ? `Review Process id  ${userToBeApproved?.mcm3RevProcess}`
                    : 'Process does not started yet'}
                </span>
              </div>
            </div>
          </div>
        )
      })}
      {!usersUnderReview[0] && (
        <h1>There are no educators to enter the register for approval</h1>
      )}
      <button
        className="btn btn-outline-primary my-3"
        onClick={handleClose}
        type="button"
      >
        back
      </button>
    </div>
  )
}

export default ListOfEducatorsForApproval
