import React, { useState } from 'react'

import { navigate } from 'gatsby'
import Tilt from 'react-parallax-tilt'
import { defaultLanguage } from '../../../../prismic-configuration'
import bgCertificateImage from '../../../images/Certificate_Committee.jpg'
import usePDFGenerator from '../../../utils/useIssueCertificate'
import AccessReviewProcess from './AccessReviewProcess'

const McmTab = ({
  lang,
  email,
  userId,
  firestore,
  buttons,
  formWStripeVariables,
  location,
  country,
  full_name,
  usersDataObject,
}) => {
  const [accessReviewProcess, setAccessReviewProcess] = useState(false)
  const [listOfEducatorsForApproval, setListOfEducatorsForApproval] =
    useState(false)
  const [listOfEducatorsForRegister, setListOfEducatorsForRegister] =
    useState(false)
  const [myENTERPortfolioFormOn, setMyENTERPortfolioFormOn] = useState(false)
  const { PdfDownLink, CommitteeCertificate } = usePDFGenerator({
    bgCertificateImage,
    full_name,
    currentUserProfileData: usersDataObject,
    type: 'committee',
    committeeName: 'Monitoring Committee',
  })

  const handleClose = async () => {
    setAccessReviewProcess(false)
    setListOfEducatorsForRegister(false)
    setListOfEducatorsForApproval(false)
    setMyENTERPortfolioFormOn(false)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const Buttons = () => {
    const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`

    return (
      <div className="personal-page-buttons_wrapper">
        {buttons.map((btn, key) => {
          const handleOnClick = () => {
            if (btn.link === 'access_review_process') {
              setAccessReviewProcess(true)
            }
            if (btn.link === 'code_of_ethics') {
              navigate(
                'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/documents%2FENTER%20Code%20of%20Ethics.pdf?alt=media&token=93b210dd-4ac1-4f96-baab-c15980929a58',
              )
            }
            if (btn.link === 'documents') {
              navigate(`${localLinkResolver}/mcm-documents`)
            }
            if (btn.link === 'issue') {
            }
          }
          return (
            <Tilt
              key={key}
              tiltMaxAngleX={6}
              tiltMaxAngleY={6}
              perspective={900}
              scale={1.05}
              transitionSpeed={2000}
              gyroscope={true}
            >
              {!!(btn.link === 'issue') ? (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                >
                  {' '}
                  {!btn.active ? btn.label : <PdfDownLink />}
                </div>
              ) : (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                  onClick={handleOnClick}
                >
                  {btn.label}
                </div>
              )}
            </Tilt>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      {!listOfEducatorsForApproval &&
        !accessReviewProcess &&
        !myENTERPortfolioFormOn &&
        !listOfEducatorsForRegister && <Buttons />}

      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PDFViewer style={{ width: '581px', height: '903px' }}>
          <CommitteeCertificate />
        </PDFViewer>
      </div> */}

      {accessReviewProcess && (
        <AccessReviewProcess
          usersDataObject={usersDataObject}
          userId={userId}
          firestore={firestore}
          formWStripeVariables={formWStripeVariables}
          location={location}
          full_name={full_name}
          email={email}
          country={country}
          handleClose={handleClose}
          lang={lang}
        />
      )}
    </div>
  )
}

export default McmTab
