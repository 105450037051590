export const committeeYearSince = (props) => {
  switch (props.committeeName) {
    // monitoring_committee_member
    // props.currentUserProfileData?.monitoring_committee_member_since
    case 'Monitoring Committee':
      return (
        props.currentUserProfileData?.monitoring_committee_member_since ||
        'Missing Year for Monitoring Committee'
      )

    // qa_committee_member
    // qa_committee_member_since
    case 'Quality Assurance Committee':
      return (
        props.currentUserProfileData?.qa_committee_member_since ||
        'Missing Year for Quality Assurance Committee'
      )

    // academic_committee_member
    // academic_committee_member_since
    case 'Academic Committee':
      return (
        props.currentUserProfileData?.academic_committee_member_since ||
        'Missing Year for Academic Committee'
      )

    // expert_committee_member
    // expert_committee_member_since
    case 'Expert Committee':
      return (
        props.currentUserProfileData?.expert_committee_member_since ||
        'Missing Year for Expert Committee'
      )

    default:
      return null
  }
}
