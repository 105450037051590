import React from 'react'

import { navigate } from 'gatsby'
import Tilt from 'react-parallax-tilt'
import bgCertificateImage from '../../../images/Certificate_Committee.jpg'
import usePDFGenerator from '../../../utils/useIssueCertificate'

const ExpertCommitteeMember = ({ buttons, full_name, usersDataObject }) => {
  const { PdfDownLink, CommitteeCertificate } = usePDFGenerator({
    bgCertificateImage,
    full_name,
    currentUserProfileData: usersDataObject,
    type: 'committee',
    committeeName: 'Expert Committee',
  })
  const Buttons = () => {
    return (
      <div className="personal-page-buttons_wrapper">
        {buttons.map((btn, key) => {
          const handleOnClick = () => {
            if (btn.link === 'access_review_process') {
            }
            if (btn.link === 'code_of_ethics') {
              navigate(
                'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/documents%2FENTER%20Code%20of%20Ethics.pdf?alt=media&token=93b210dd-4ac1-4f96-baab-c15980929a58',
              )
            }
            if (btn.link === 'documents') {
              navigate('https://enterprof.org/expert-com-documents')
            }
          }
          return (
            <Tilt
              key={key}
              tiltMaxAngleX={6}
              tiltMaxAngleY={6}
              perspective={900}
              scale={1.05}
              transitionSpeed={2000}
              gyroscope={true}
            >
              {!!(btn.link === 'issue') ? (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                >
                  {' '}
                  {!btn.active ? btn.label : <PdfDownLink />}
                </div>
              ) : (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                  onClick={handleOnClick}
                >
                  {btn.label}
                </div>
              )}
            </Tilt>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <Buttons />
    </div>
  )
}

export default ExpertCommitteeMember
