import React, { useState } from 'react';

import { navigate } from 'gatsby';
import Tilt from 'react-parallax-tilt';
import ListOfEducatorsForApproval from './ListOfEducatorsForApproval';
import ListOfEducatorsForRegister from './ListOfEducatorsForRegister';
import UsersPermissions from './UsersPermissions';

const SecretaryTab = ({
  formLabelsFromPrismic,
  defaultLanguage,
  firstFormVars,
  secondFormVars,
  lang,
  email,
  userId,
  userUid,
  firestore,
  buttons,
  formWStripeVariables,
  location,
  country,
  full_name,
  usersDataObject,
  firebaseAuth,
}) => {
  const [usersPermissions, setUsersPermissions] = useState(false);
  const [listOfEducatorsForApproval, setListOfEducatorsForApproval] = useState(false);
  const [listOfEducatorsForRegister, setListOfEducatorsForRegister] = useState(false);
  const [myENTERPortfolioFormOn, setMyENTERPortfolioFormOn] = useState(false);

  const handleClose = async () => {
    setUsersPermissions(false);
    setListOfEducatorsForRegister(false);
    setListOfEducatorsForApproval(false);
    setMyENTERPortfolioFormOn(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const Buttons = () => {
    const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;

    return (
      <div className="personal-page-buttons_wrapper">
        {buttons.map((btn, key) => {
          const handleOnClick = () => {
            if (btn.link === 'usersPermissions') {
              setUsersPermissions(true);
            }
            if (btn.link === 'listOfEducatorsForApproval') {
              setListOfEducatorsForApproval(true);
            }
            if (btn.link === 'listOfEducatorsForRegister') {
              setListOfEducatorsForRegister(true);
            }
            if (btn.link === 'MyENTERPortfolio') {
              navigate(`${localLinkResolver}/personal-portfolio`);
              setUsersPermissions(false);
              setListOfEducatorsForRegister(false);
              setListOfEducatorsForApproval(false);
            }
          };
          return (
            <Tilt
              key={key}
              tiltMaxAngleX={6}
              tiltMaxAngleY={6}
              perspective={900}
              scale={1.05}
              transitionSpeed={2000}
              gyroscope={true}
            >
              <div
                className={`text-wrap personal-page-buttons_card ${!btn.active ? 'inactive' : ''}`}
                onClick={handleOnClick}
              >
                {btn.label}
              </div>
            </Tilt>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {!listOfEducatorsForApproval &&
        !usersPermissions &&
        !myENTERPortfolioFormOn &&
        !listOfEducatorsForRegister && <Buttons />}
      {listOfEducatorsForApproval && (
        <ListOfEducatorsForApproval
          usersDataObject={usersDataObject}
          userId={userId}
          firestore={firestore}
          formWStripeVariables={formWStripeVariables}
          location={location}
          full_name={full_name}
          email={email}
          country={country}
          handleClose={handleClose}
          lang={lang}
          defaultLanguage={defaultLanguage}
        />
      )}
      {listOfEducatorsForRegister && (
        <ListOfEducatorsForRegister
          usersDataObject={usersDataObject}
          userId={userId}
          firestore={firestore}
          formWStripeVariables={formWStripeVariables}
          location={location}
          full_name={full_name}
          email={email}
          country={country}
          handleClose={handleClose}
        />
      )}
      {usersPermissions && (
        <UsersPermissions
          formLabelsFromPrismic={formLabelsFromPrismic}
          defaultLanguage={defaultLanguage}
          lang={lang}
          usersDataObject={usersDataObject}
          userId={userId}
          userUid={userUid}
          firestore={firestore}
          formWStripeVariables={formWStripeVariables}
          location={location}
          full_name={full_name}
          email={email}
          country={country}
          firstFormVars={firstFormVars}
          secondFormVars={secondFormVars}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default SecretaryTab;
