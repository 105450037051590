/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase';

export const useCurrentProfileData = () => {
  const [usersList, setUsersList] = useState([]);
  const [userId, setUserId] = useState('');
  /**
   * @type {any}
   */
  const [userFromAuth, setUserFromAuth] = useState('');
  const isBrowser = typeof window !== 'undefined';

  // fetch if exist Firebase firestore(database) data
  useEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            // @ts-ignore
            setUsersList(listUsers);

            const user = firebase.auth().currentUser;
            // @ts-ignore
            setUserId(user?.uid);
            setUserFromAuth(user);
          });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  /**
   * @type {any}
   */
  const currentUserProfileData = useMemo(
    // @ts-ignore
    () => usersList?.filter(userFromList => userFromList?.uid === userId)?.[0],
    [userId, usersList],
  );

  return { currentUserProfileData, userFromAuth, usersList };
};
