export const committeeQrCodeImage = (committeeName) => {
  switch (committeeName) {
    case 'Monitoring Committee':
      return 'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/qrCodes%2FQR%20Code%20Monitoring%20Committee.jpeg?alt=media&token=ed3fd996-d7de-4272-82aa-e1bef53b6004'

    case 'Quality Assurance Committee':
      return 'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/qrCodes%2FQRCode%20QA%20Committee.jpeg?alt=media&token=77fe4570-7ec8-45c3-bb2b-62947b28cc87'

    case 'Academic Committee':
      return 'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/qrCodes%2FQRCode%20Academic%20Committee.jpeg?alt=media&token=0d9ee037-383d-4390-a6d5-d2db514f08a6'

    case 'Expert Committee':
      return 'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/qrCodes%2FQR%20Code%20Expert%20Committee.jpeg?alt=media&token=26f30a47-7540-4a59-8ad9-2cd226f06dde'

    default:
      return null
  }
}
