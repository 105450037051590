/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';

export const initialFilterState = {
  academicCommittee: false,
  monitoringCommittee: false,
  qaCommittee: false,
  expertCommittee: false,
  contactPersonInstitutionAccess: false,
  contactPersonAccreditationAgencyAccess: false,
  portfolioUnderReview: false,
  portfolioReviewed: false,
  registered: false,
};

const filterTogglesReducer = (
  /** @type {{ academicCommittee: string; monitoringCommittee: string; qaCommittee: string; expertCommittee: string; contactPersonInstitutionAccess: string; contactPersonAccreditationAgencyAccess: string; portfolioUnderReview: string; portfolioReviewed: string; registered: string; }} */ state,
  /** @type {{ type: string; }} */ action,
) => {
  switch (action.type) {
    case 'Academic Committee':
      return {
        ...state,
        academicCommittee: !state.academicCommittee,
      };
    case 'Monitoring Committee':
      return {
        ...state,
        monitoringCommittee: !state.monitoringCommittee,
      };
    case 'QA Committee':
      return {
        ...state,
        qaCommittee: !state.qaCommittee,
      };
    case 'Expert Committee':
      return {
        ...state,
        expertCommittee: !state.expertCommittee,
      };
    case 'Contact Person Institution Access':
      return {
        ...state,
        contactPersonInstitutionAccess: !state.contactPersonInstitutionAccess,
      };
    case 'Contact Person Accreditation Agency Access':
      return {
        ...state,
        contactPersonAccreditationAgencyAccess: !state.contactPersonAccreditationAgencyAccess,
      };
    case 'Portfolio Under Review':
      return {
        ...state,
        portfolioUnderReview: !state.portfolioUnderReview,
      };
    case 'Portfolio Reviewed':
      return {
        ...state,
        portfolioReviewed: !state.portfolioReviewed,
      };
    case 'Registered':
      return {
        ...state,
        registered: !state.registered,
      };
    case 'Clear All':
      return { initialFilterState };

    default:
      break;
  }
};

export const useFilterToggles = () => {
  // @ts-ignore

  // @ts-ignore
  const [filterState, filterDispatch] = useReducer(filterTogglesReducer, initialFilterState);

  const isFilterInUse =
    // @ts-ignore
    filterState.academicCommittee ||
    // @ts-ignore
    filterState.monitoringCommittee ||
    // @ts-ignore
    filterState.qaCommittee ||
    // @ts-ignore
    filterState.expertCommittee ||
    // @ts-ignore
    filterState.contactPersonInstitutionAccess ||
    // @ts-ignore
    filterState.contactPersonAccreditationAgencyAccess ||
    // @ts-ignore
    filterState.portfolioUnderReview ||
    // @ts-ignore
    filterState.portfolioReviewed ||
    // @ts-ignore
    filterState.registered;
  // @ts-ignore
  return { filterState, filterDispatch, isFilterInUse };
};
