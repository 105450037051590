import { navigate } from 'gatsby';
import React, { useState } from 'react';
import Tilt from 'react-parallax-tilt';
import { defaultLanguage } from '../../../../prismic-configuration';
import bgCertificateImage from '../../../images/Certificate_Educators.jpg';
import usePDFGenerator from '../../../utils/useIssueCertificate';
import PersonalDataFormWStripe from '../../Form-components/PersonalDataForm/PersonalDataFormWStripe';
import { Status } from './Status';

const EducatorTab = ({
  firstFormVars,
  secondFormVars,
  currentUserProfileData,
  lang,
  email,
  userId,
  firestore,
  buttons,
  formWStripeVariables,
  location,
  country,
  full_name,
  usersDataObject,
}) => {
  const [myApplicationFormOn, setMyApplicationFormOn] = useState(false);
  const [myENTERPortfolioFormOn, setMyENTERPortfolioFormOn] = useState(false);
  const [isCurrentStatusOn, setIsCurrentStatusOn] = useState(false);

  const { PdfDownLink, EducatorCertificate } = usePDFGenerator({
    bgCertificateImage,
    full_name,
    currentUserProfileData,
    type: 'educator',
  });

  const handleClose = async () => {
    setMyApplicationFormOn(false);
    setMyENTERPortfolioFormOn(false);
    setIsCurrentStatusOn(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const Buttons = () => {
    const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
    return (
      <div className="personal-page-buttons_wrapper">
        {buttons.map((btn, key) => {
          const handleOnClick = () => {
            if (btn.link === 'MyApplicationForm') {
              setMyApplicationFormOn(true);
              setIsCurrentStatusOn(false);
            }
            if (btn.link === 'currentStatus') {
              setMyApplicationFormOn(false);
              setIsCurrentStatusOn(true);
            }
            if (btn.link === 'MyENTERPortfolio' && currentUserProfileData?.requestID !== '') {
              navigate(`${localLinkResolver}/personal-portfolio`);
              setMyApplicationFormOn(false);
              setIsCurrentStatusOn(false);
            }
            if (btn.link === 'MyENTERPortfolio' && currentUserProfileData?.requestID === '') {
              alert('Please first fill up and Submit "My Application Form"');
            }
            if (btn.link === 'MyENTERPortfolio' && currentUserProfileData?.requestID === '') {
              alert('Please first fill up and Submit "My Application Form"');
            }
          };
          return (
            <Tilt
              key={key}
              tiltMaxAngleX={6}
              tiltMaxAngleY={6}
              perspective={900}
              scale={1.05}
              transitionSpeed={2000}
              gyroscope={true}
            >
              {!!(btn.link === 'issue') ? (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                >
                  {' '}
                  {!btn.active ? btn.label : <PdfDownLink />}
                  {btn.status && (
                    <span
                      style={{
                        maxWidth: '250px',
                        padding: '0.3rem',
                        borderRadius: '15px',
                      }}
                    >
                      {btn.status}
                    </span>
                  )}
                </div>
              ) : (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                  onClick={handleOnClick}
                >
                  {btn.label}
                  {btn.status && (
                    <span
                      style={{
                        maxWidth: '250px',
                        padding: '0.3rem',
                        borderRadius: '15px',
                      }}
                    >
                      {btn.status}
                    </span>
                  )}
                </div>
              )}
            </Tilt>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {!myApplicationFormOn && !myENTERPortfolioFormOn && !isCurrentStatusOn && <Buttons />}

      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PDFViewer style={{ width: '581px', height: '903px' }}>
          <EducatorCertificate />
        </PDFViewer>
        <button
          onClick={() => {
            getSecondPaymentApplicant()
          }}
        >
          click
        </button>
        <img src={imageUrlBase64} alt="ds" />
      </div> */}

      {myApplicationFormOn && (
        <div>
          <button className="btn btn-outline-primary" onClick={handleClose} type="button">
            back
          </button>
          <PersonalDataFormWStripe
            usersDataObject={usersDataObject}
            userId={userId}
            firestore={firestore}
            formWStripeVariables={formWStripeVariables}
            location={location}
            full_name={full_name}
            email={email}
            country={country}
            handleClose={handleClose}
          />
        </div>
      )}
      {isCurrentStatusOn && (
        <Status
          currentUserProfileData={currentUserProfileData}
          firstFormVars={firstFormVars}
          secondFormVars={secondFormVars}
          handleClose={handleClose}
          location={location}
          firestore={firestore}
        />
      )}
    </div>
  );
};

export default EducatorTab;
