import React from 'react';

import Tilt from 'react-parallax-tilt';
import { navigate } from 'gatsby';
import { defaultLanguage } from '../../../../prismic-configuration';

function CpAnyInstitution({ lang, buttons }) {
  function Buttons() {
    const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;

    return (
      <div className="personal-page-buttons_wrapper">
        {buttons.map((btn, key) => {
          const handleOnClick = () => {
            if (btn.link === 'access_review_process') {
              // setAccessReviewProcess(true)
            }
            if (btn.link === 'code_of_ethics') {
              navigate(
                'https://firebasestorage.googleapis.com/v0/b/enter-educators.appspot.com/o/documents%2FENTER%20Code%20of%20Ethics.pdf?alt=media&token=93b210dd-4ac1-4f96-baab-c15980929a58',
              );
            }
          };
          return (
            <Tilt
              key={key}
              tiltMaxAngleX={6}
              tiltMaxAngleY={6}
              perspective={900}
              scale={1.05}
              transitionSpeed={2000}
              gyroscope
            >
              <div
                className={`text-wrap personal-page-buttons_card ${!btn.active ? 'inactive' : ''}`}
                onClick={handleOnClick}
              >
                {btn.label}
              </div>
            </Tilt>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Buttons />
    </div>
  );
}

export default CpAnyInstitution;
