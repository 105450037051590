import React, { useState, useEffect } from 'react'
import firebase from 'firebase'

import { Link } from 'gatsby'
import { defaultLanguage } from '../../../../prismic-configuration'

const isBrowser = typeof window !== 'undefined'

const ListOfEducatorsToBeApproved = ({
  userId,
  full_name,
  email,
  country,
  topMenu,
  activeDoc,
  formWStripeVariables,
  saveForm,
  location,
  handleClose,
  usersDataObject,
  lang,
}) => {
  const [usersList, setUsersList] = useState([])
  const [currentUserProfileData, setCurrentUserProfileData] = useState([])

  //fetch if exist Firebase firestore(database) data
  useEffect(() => {
    let isMounted = true
    if (isBrowser && isMounted) {
      try {
        //Accesses your Firestore database
        firestore
          //Access the "users" collection
          .collection('usersCollection')

          .onSnapshot((snapshot) => {
            const listUsers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            setUsersList(listUsers)

            const usersPersonalData = listUsers.filter(
              (userFromList) =>
                userFromList.uid === firebase.auth().currentUser?.uid,
            )
            setCurrentUserProfileData(usersPersonalData[0])
          })
      } catch (error) {
        console.log(error)
      }
    }
    return () => {
      isMounted = false
    }
  }, [])
  let user = ''
  let firebaseAuth = {}

  let firestore = {}
  if (isBrowser) {
    user = firebase.auth().currentUser
    firebaseAuth = firebase.auth()

    firestore = firebase.firestore()
  }

  const usersUnderReview = usersList.filter((user) => {
    let output =
      (user?.under_review &&
        !user?.mcm1ReviewSubmited &&
        user?.mcm1 === currentUserProfileData?.id) ||
      (user?.under_review &&
        !user?.mcm2ReviewSubmited &&
        user?.mcm2 === currentUserProfileData?.id) ||
      (user?.under_review &&
        !user?.mcm3ReviewSubmited &&
        user?.mcm3 === currentUserProfileData?.id)
    return output
  })

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`

  // console.log(currentUserProfileData)

  return (
    <div>
      <h1>List of the Candidates</h1>
      <div className="d-flex flex-row mt-2">
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={handleClose}
          type="button"
        >
          Back
        </button>
      </div>
      <div>
        {usersUnderReview.map((userUnderReview, key) => {
          return (
            <div
              key={key}
              className="border border-3 border-info rounded-3 p-3 mt-3 mb-5"
            >
              <div className="d-flex flex-column">
                <div className="d-flex flex-column col-12 border-start-0 border-end-0 border border-1 border-dark px-3 py-1">
                  <span className="col-sm-12 col-md-2">Candidate's Name:</span>{' '}
                  <span className="text-info fw-bold">
                    {userUnderReview.full_name}
                  </span>
                </div>
                <div className="d-flex flex-column col-12 border-start-0 border-top-0 border-end-0 border border-1 border-dark px-3 py-1">
                  <span className="col-sm-12 col-md-2">ID :</span>{' '}
                  <span className="text-info fw-bold">
                    {userUnderReview.id}
                  </span>
                </div>
                <div className="d-flex flex-column col-12 border-start-0 border-top-0 border-end-0 border border-1 border-dark px-3 py-1">
                  <span className="col-sm-12 col-md-2">
                    Review start date :
                  </span>{' '}
                  <span className="text-info fw-bold">
                    {userUnderReview.dateAppliedForReview}
                  </span>
                </div>
              </div>

              <br />
              <a
                // to={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${userUnderReview.id}`}
                href={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${userUnderReview.id}`}
                className="btn btn-sm btn-outline-dark mx-2"
                rel="noreferrer noopener"
                target="_blank"
              >
                Full Portfolio of the Candidate
              </a>
              <a
                href={`${localLinkResolver}/enter-educators/mcm-review-form?userId=${userUnderReview.id}`}
                className="btn btn-sm btn-outline-dark mx-2"
                rel="noreferrer noopener"
                target="_blank"
              >
                Review Form
              </a>
            </div>
          )
        })}
      </div>
      <div className="d-flex flex-row mt-2">
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={handleClose}
          type="button"
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default ListOfEducatorsToBeApproved
