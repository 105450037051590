import React from 'react';

import { navigate } from 'gatsby';
import Tilt from 'react-parallax-tilt';
import { defaultLanguage } from '../../../../prismic-configuration';
import bgCertificateImage from '../../../images/Certificate_Committee.jpg';
import usePDFGenerator from '../../../utils/useIssueCertificate';

function AcademicСommitteeMember({
  lang,

  buttons,

  full_name,
  usersDataObject,
}) {
  function Buttons() {
    const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
    const { PdfDownLink, CommitteeCertificate } = usePDFGenerator({
      bgCertificateImage,
      full_name,
      currentUserProfileData: usersDataObject,
      type: 'committee',
      committeeName: 'Academic Committee',
    });
    return (
      <div className="personal-page-buttons_wrapper">
        {buttons.map((btn, key) => {
          const handleOnClick = () => {
            if (btn.link === 'access_review_process') {
              // setAccessReviewProcess(true)
            }
            if (btn.link === 'list_of_members') {
              navigate(`${localLinkResolver}/academic-committee-members`);
            }
            if (btn.link === 'documents') {
              navigate(`${localLinkResolver}/academ-com-documents`);
            }
          };
          return (
            <Tilt
              key={key}
              tiltMaxAngleX={6}
              tiltMaxAngleY={6}
              perspective={900}
              scale={1.05}
              transitionSpeed={2000}
              gyroscope
            >
              {btn.link === 'issue' ? (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                >
                  {' '}
                  {!btn.active ? btn.label : <PdfDownLink />}
                </div>
              ) : (
                <div
                  className={`text-wrap personal-page-buttons_card ${
                    !btn.active ? 'inactive' : ''
                  }`}
                  onClick={handleOnClick}
                >
                  {btn.label}
                </div>
              )}
            </Tilt>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Buttons />
    </div>
  );
}

export default AcademicСommitteeMember;
