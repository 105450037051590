import React, { useEffect, useState } from 'react';

export function DataFromReviewers({ currentUserProfileData, firstFormVars, secondFormVars }) {
  const [formFieldEntriesMCM1, setFormFieldEntriesMCM1] = useState([]);
  const [formFieldEntriesMCM2, setFormFieldEntriesMCM2] = useState([]);
  const [formFieldEntriesMCM3, setFormFieldEntriesMCM3] = useState([]);
  useEffect(() => {
    const entriesMCM1 = Object.entries(currentUserProfileData.mcm1ReviewProcess ?? []);
    setFormFieldEntriesMCM1(entriesMCM1);
    const entriesMCM2 = Object.entries(currentUserProfileData.mcm2ReviewProcess ?? []);
    setFormFieldEntriesMCM2(entriesMCM2);
    const entriesMCM3 = Object.entries(currentUserProfileData.mcm3ReviewProcess ?? []);
    setFormFieldEntriesMCM3(entriesMCM3);
  }, [currentUserProfileData]);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const validUntil = !!currentUserProfileData?.data?.valid_until_date
    ? currentUserProfileData?.data?.valid_until_date
    : currentUserProfileData?.regValidUntil?.toDate().toLocaleString([], options) || 'N/A';

  const regLevel = !!currentUserProfileData?.data?.reg_level
    ? currentUserProfileData?.data?.reg_level
    : currentUserProfileData?.regLevel || 'Educator';

  const regNumber = !!currentUserProfileData?.data?.code?.text
    ? currentUserProfileData?.data?.code?.text
    : currentUserProfileData?.regNumber || 'N/A';

  const full_name = !!currentUserProfileData?.data?.name?.text
    ? currentUserProfileData?.data?.name?.text
    : currentUserProfileData?.full_name || 'N/A';

  const tableData = [
    { 'Full name of the Educator': full_name },
    {
      'ENTER Register registration number': regNumber,
    },
    {
      'Attributed Registration Level': regLevel,
    },
    {
      'Valid until': validUntil,
    },
  ];

  const RepresentationTable = () => {
    const FieldValue = ({ field, value }) => {
      return (
        <div
          className="table_row w-100 border-top border-dark d-flex justify-content-between "
          // style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="table_row__field w-50 py-2 px-5">{field}</div>
          <div className="table_row__value w-50 border-start border-dark py-2 px-5">{value}</div>
        </div>
      );
    };

    return (
      <div className="border-bottom border-dark mb-5">
        {tableData.map((row, key) => {
          let fieldValue;
          let fieldKey;
          for (const [key, value] of Object.entries(row)) {
            fieldValue = value;
            fieldKey = key;
          }
          return <FieldValue key={key} field={fieldKey} value={fieldValue} />;
        })}
      </div>
    );
  };

  return (
    <div className="border border-3 shadow">
      <RepresentationTable />
      <h1 style={{ margin: '0 auto', width: 'fit-content' }}>Peer evaluation comments</h1>
      <div className="final-step-approval-proccess border border-1">
        {/* formFieldEntriesMCM1 */}
        <div className="fullPersonalPortfolio p-4">
          {firstFormVars?.map((field, key) => {
            //name: "applicantID"
            const fieldValueArray = formFieldEntriesMCM1.filter(
              userField => userField[0] === field.name,
            );

            let fieldValue;

            for (const [key, value] of Object.entries(fieldValueArray)) {
              fieldValue = value[1];
            }
            if (field.name === 'applicantID') {
              return;
            }
            if (field.name === 'mcm_name') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  Review : 1
                </li>
              );
            }
            if (field.field_type === 'Long Input') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  {field.placeholder}{' '}
                  <span
                    className={`value long-input border  border-1  rounded-3 text-break ${
                      fieldValue ? 'border-info' : 'border-secondary'
                    }`}
                  >
                    {fieldValue ? fieldValue : 'Not provided'}
                  </span>
                </li>
              );
            }
            return (
              <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                {field.title}{' '}
                <span
                  className={`value border  border-1  rounded-3 text-break ${
                    fieldValue ? 'border-info' : 'border-secondary'
                  }`}
                >
                  {fieldValue ? fieldValue : 'Not provided'}
                </span>
              </li>
            );
          })}
          {secondFormVars?.map((field, key) => {
            const fieldValueArray = formFieldEntriesMCM1.filter(
              userField => userField[0] === field.name,
            );
            let fieldValue;

            for (const [key, value] of Object.entries(fieldValueArray)) {
              fieldValue = value[1];
            }
            if (field.field_type === 'Long Input') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  {field.placeholder}{' '}
                  <span
                    className={`value long-input  border  border-1  rounded-3 text-break ${
                      fieldValue ? 'border-info' : 'border-secondary'
                    }`}
                  >
                    {fieldValue ? fieldValue : 'Not provided'}
                  </span>
                </li>
              );
            }
            return (
              <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                {field.title}{' '}
                <span
                  className={`value border  border-1  rounded-3 text-break ${
                    fieldValue ? 'border-info' : 'border-secondary'
                  }`}
                >
                  {fieldValue ? fieldValue : 'Not provided'}
                </span>
              </li>
            );
          })}
        </div>
        {/* formFieldEntriesMCM2 */}
        <div className="fullPersonalPortfolio p-4">
          {firstFormVars?.map((field, key) => {
            const fieldValueArray = formFieldEntriesMCM2.filter(
              userField => userField[0] === field.name,
            );
            let fieldValue;

            for (const [key, value] of Object.entries(fieldValueArray)) {
              fieldValue = value[1];
            }
            if (field.name === 'applicantID') {
              return;
            }
            if (field.name === 'mcm_name') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  Review : 2
                </li>
              );
            }
            if (field.field_type === 'Long Input') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  {field.placeholder}{' '}
                  <span
                    className={`value long-input border  border-1  rounded-3 text-break ${
                      fieldValue ? 'border-info' : 'border-secondary'
                    }`}
                  >
                    {fieldValue ? fieldValue : 'Not provided'}
                  </span>
                </li>
              );
            }
            return (
              <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                {field.title}{' '}
                <span
                  className={`value border  border-1  rounded-3 text-break ${
                    fieldValue ? 'border-info' : 'border-secondary'
                  }`}
                >
                  {fieldValue ? fieldValue : 'Not provided'}
                </span>
              </li>
            );
          })}
          {secondFormVars?.map((field, key) => {
            const fieldValueArray = formFieldEntriesMCM2.filter(
              userField => userField[0] === field.name,
            );
            let fieldValue;

            for (const [key, value] of Object.entries(fieldValueArray)) {
              fieldValue = value[1];
            }
            if (field.field_type === 'Long Input') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  {field.placeholder}{' '}
                  <span
                    className={`value long-input border  border-1  rounded-3 text-break ${
                      fieldValue ? 'border-info' : 'border-secondary'
                    }`}
                  >
                    {fieldValue ? fieldValue : 'Not provided'}
                  </span>
                </li>
              );
            }
            return (
              <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                {field.title}{' '}
                <span
                  className={`value border  border-1  rounded-3 text-break ${
                    fieldValue ? 'border-info' : 'border-secondary'
                  }`}
                >
                  {fieldValue ? fieldValue : 'Not provided'}
                </span>
              </li>
            );
          })}
        </div>
        {/* formFieldEntriesMCM3 */}
        <div className="fullPersonalPortfolio p-4">
          {firstFormVars?.map((field, key) => {
            const fieldValueArray = formFieldEntriesMCM3.filter(
              userField => userField[0] === field.name,
            );
            let fieldValue;

            for (const [key, value] of Object.entries(fieldValueArray)) {
              fieldValue = value[1];
            }
            if (field.name === 'applicantID') {
              return;
            }
            if (field.name === 'mcm_name') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  Review : 3
                </li>
              );
            }
            if (field.field_type === 'Long Input') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  {field.placeholder}{' '}
                  <span
                    className={`value long-input border  border-1  rounded-3 text-break ${
                      fieldValue ? 'border-info' : 'border-secondary'
                    }`}
                  >
                    {fieldValue ? fieldValue : 'Not provided'}
                  </span>
                </li>
              );
            }
            return (
              <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                {field.title}{' '}
                <span
                  className={`value border  border-1  rounded-3 text-break ${
                    fieldValue ? 'border-info' : 'border-secondary'
                  }`}
                >
                  {fieldValue ? fieldValue : 'Not provided'}
                </span>
              </li>
            );
          })}
          {secondFormVars?.map((field, key) => {
            const fieldValueArray = formFieldEntriesMCM3.filter(
              userField => userField[0] === field.name,
            );
            let fieldValue;

            for (const [key, value] of Object.entries(fieldValueArray)) {
              fieldValue = value[1];
            }
            if (field.field_type === 'Long Input') {
              return (
                <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                  {field.placeholder}{' '}
                  <span
                    className={`value long-input border  border-1  rounded-3 text-break ${
                      fieldValue ? 'border-info' : 'border-secondary'
                    }`}
                  >
                    {fieldValue ? fieldValue : 'Not provided'}
                  </span>
                </li>
              );
            }
            return (
              <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                {field.title}{' '}
                <span
                  className={`value border  border-1  rounded-3 text-break ${
                    fieldValue ? 'border-info' : 'border-secondary'
                  }`}
                >
                  {fieldValue ? fieldValue : 'Not provided'}
                </span>
              </li>
            );
          })}
        </div>
      </div>
      <div className="general_comments p-3 bg-light bg-gradient">
        <h5 className="general_comments__title">General Comments</h5>
        <div className="general_comments__body">
          {currentUserProfileData.secretaryGeneralComments || 'N/A'}
        </div>
      </div>
    </div>
  );
}
