import axios from 'axios';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { DataFromReviewers } from './FormDataFromReviewers';
import { DeclarePermissions } from './components/DeclareYourPermission';

export const Status = ({
  currentUserProfileData,
  handleClose,
  firstFormVars,
  secondFormVars,
  location,
  firestore,
}) => {
  const [status, setStatus] = useState('');
  useEffect(() => {
    setStatus(currentUserProfileData?.currentStatus);
  }, [currentUserProfileData]);

  const handleSecondRedirectToStripe = async values => {
    // console.log('values inside Handle Redirect to stripe: >>', values) // http://localhost:8000/enter-educators/final-step-approval-proccess?mcm1RevProcess=ZIFR8hdwvStHvFkDHhLm&mcm2RevProcess=lXE1lO6koo4ogBpUpBzm&mcm3RevProcess=2yfyecn4fiLabj25mZCV

    try {
      // Send data to the function,
      // await the result.
      const result = await axios.post('/api/personal-application-fee-registration-and-card', {
        full_name: currentUserProfileData.full_name,
        isMCM: currentUserProfileData.monitoring_committee_member,
        country: currentUserProfileData.country,
        email: currentUserProfileData.email, //also can take the email value from value.email. But then I have to add this field in prismic
        isSecondPayment: true, // stripe will sent it as a "String"
        cancelUrl: `${location.origin}/personal-page`, //?payment=cancelled
        //can create canceled page where we can ask, "Would you like to continue to correct your form submission or to start from beginning"
        successUrl: `${location.origin}/p-success/?session_id={CHECKOUT_SESSION_ID}&secondPayment=true`,
      });
      window.location = result.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const StatusContainer = () => {
    switch (status) {
      case 'Portfolio not submitted':
        return (
          <div>Your Portfolio has not yet been submitted. Please complete My ENTER Portfolio.</div>
        );

      case 'Portfolio under review':
        return (
          <div>
            Your Portfolio has been successfully submitted.
            <br /> The review process is ongoing.
            <br /> You will be notified by e-mail once the review process is completed.
            <br /> You can also check the current status in your Personal Area at any time.
          </div>
        );
      case 'Portfolio reviewed':
        return (
          <div style={{ maxWidth: '750px', margin: ' 0 auto' }}>
            Congratulations!
            <br /> Your Portfolio has been successfully peer-reviewed.
            <br /> You were approved to be registered in the International Professional Register
            ENTER for engineering educators.
            <br />
            <br /> If you wish to be part of the ENTER Register, please,proceed to the payment of
            the registration fee. Once the payment is completed you will:
            <br /> - receive your Review results;
            <br /> - be included in the ENTER Register database;
            <div className=" d-flex flex-column gap-3 w-100">
              <DeclarePermissions
                firestore={firestore}
                currentUserProfileData={currentUserProfileData}
              />
            </div>
            <span className="btn btn-outline-primary mt-5" onClick={handleSecondRedirectToStripe}>
              Payment
            </span>{' '}
            <br />
            <br />
            <br />
          </div>
        );
      case 'Not registered':
        return (
          <div>
            <div style={{ margin: ' 0 auto' }}>
              Your Portfolio has been peer-reviewed.
              <br /> However the evaluation result does not allow to register you at this time.
              <br /> Please, check the following Review Results to get experts comments and
              recommendations.
              <br />
              <br />
              {currentUserProfileData?.showDataFromReviewers && (
                <DataFromReviewers
                  currentUserProfileData={currentUserProfileData}
                  firstFormVars={firstFormVars}
                  secondFormVars={secondFormVars}
                />
              )}
            </div>
          </div>
        );
      case 'Registered':
        return (
          <div style={{ margin: ' 0 auto' }}>
            You are now registered at the ENTER Register{' '}
            <Link style={{ textDecoration: 'underline' }} to="/enter-registered-educators">
              Database
            </Link>
            <br />
            <br />
            <div className=" d-flex flex-column p-5 gap-3 w-100">
              <DeclarePermissions
                firestore={firestore}
                currentUserProfileData={currentUserProfileData}
              />
            </div>
            <br />
            <br />
            {currentUserProfileData?.showDataFromReviewers && (
              <DataFromReviewers
                currentUserProfileData={currentUserProfileData}
                firstFormVars={firstFormVars}
                secondFormVars={secondFormVars}
              />
            )}
          </div>
        );

      default:
        return (
          <Container>
            <button className="btn btn-outline-primary" onClick={handleClose} type="button">
              back
            </button>
          </Container>
        );
    }
  };

  return (
    <Container>
      <button className="btn btn-outline-primary" onClick={handleClose} type="button">
        back
      </button>
      <br />
      <br />
      <div style={{ fontSize: '25px', marginBottom: '25px' }}>
        <span style={{ marginLeft: '15px', color: '#00c3ff' }}>Current Status : </span>
        {status}
      </div>
      <StatusContainer />
      <br />
      <br />
      <button className="btn btn-outline-primary" onClick={handleClose} type="button">
        back
      </button>
    </Container>
  );
};
