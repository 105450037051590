import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { SelectMCM } from '../../Form-components/InputFields-components'
import { Spinner } from '../../Spinner/Spinner'

const isBrowser = typeof window !== 'undefined'

const ListOfEducatorsForRegister = ({
  userId,
  firestore,
  full_name,
  email,
  country,
  topMenu,
  activeDoc,
  formWStripeVariables,
  saveForm,
  location,
  handleClose,
  usersDataObject,
  ...props
}) => {
  //fetch if exist Firebase firestore(database) data
  useEffect(() => {
    let isMounted = true
    if (isBrowser && isMounted) {
      try {
        firestore
          .collection('usersCollection')

          .onSnapshot((snapshot) => {
            const listUsers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            setUsersList(listUsers)
          })
      } catch (error) {
        console.log(error)
      }
    }
    return () => {
      isMounted = false
    }
  }, [])
  const [showFinalSpinner, setShowFinalSpinner] = useState(false)

  const [usersList, setUsersList] = useState([])
  const usersToBeReviewed = usersList.filter((user) => user?.to_be_reviewed)
  const monitoring_committee_members = usersList.filter(
    (user) => user?.monitoring_committee_member,
  )
  const handleSubmitMCM1 = async (values) => {
    if (values.mcm1 !== 'Please Select') {
      setShowFinalSpinner(true)
      const userToBeReviewed = usersToBeReviewed.filter(
        (user) => user?.id === values.userToBeReviewedID,
      )[0]

      const mcm1Email = monitoring_committee_members.filter(
        (user) => user?.id === values.mcm1,
      )[0]?.email
      const mcm1Name = monitoring_committee_members.filter(
        (user) => user?.id === values.mcm1,
      )[0]?.full_name

      const allMCMs =
        values.mcm1 +
        ', ' +
        (userToBeReviewed.mcm2 || '') +
        ', ' +
        (userToBeReviewed.mcm3 || '')
      
      // console.log('userToBeReviewed', userToBeReviewed)
      // console.log('mcm1Email', mcm1Email)
      // console.log('mcm1Name', mcm1Name)
      // console.log('allMCMs', allMCMs)

      try {
        firestore
          .collection('usersCollection')
          .doc(values.userToBeReviewedID)
          .update({
            dateAppliedForReviewMCM1: new Date().toLocaleString(), // 12/04/2022 19:12:27
            mcm1: values.mcm1,
            mcms: allMCMs,
            under_review: true,
          })
      } catch (err) {
        alert(
          'Something went wrong, on writing your data. Please contact us by email: info@enterprof.org',
        )
        console.log(err)
      }
      try {
        // Send data to the function,
        // await the result.
        await axios.post('/api/send-message-on-mcm-assigning', {
          mcmEmail: mcm1Email,
          mcmName: mcm1Name,
        })
      } catch (error) {
        alert(
          'Something went wrong, sending confirmation email. Please contact us by email: info@enterprof.org',
        )
        console.log(error)
      }

      alert('Success!')
    } else {
      alert('Please select mcm member!')
    }
    setShowFinalSpinner(false)
  }
  const handleSubmitMCM2 = async (values) => {
    if (values.mcm2 !== 'Please Select') {
      setShowFinalSpinner(true)
      const userToBeReviewed = usersToBeReviewed.filter(
        (user) => user?.id === values.userToBeReviewedID,
      )[0]

      const mcm2Email = monitoring_committee_members.filter(
        (user) => user?.id === values.mcm2,
      )[0]?.email
      const mcm2Name = monitoring_committee_members.filter(
        (user) => user?.id === values.mcm2,
      )[0]?.full_name

      const allMCMs =
        (userToBeReviewed.mcm1 || '') +
        ', ' +
        values.mcm2 +
        ', ' +
        (userToBeReviewed.mcm3 || '')
      try {
        firestore
          .collection('usersCollection')
          .doc(values.userToBeReviewedID)
          .update({
            dateAppliedForReviewMCM2: new Date().toLocaleString(), // 12/04/2022 19:12:27
            mcm2: values.mcm2,
            mcms: allMCMs,
            under_review: true,
          })
      } catch (err) {
        alert(
          'Something went wrong, on writing your data. Please contact us by email: info@enterprof.org',
        )
        console.log(err)
      }
      try {
        // Send data to the function,
        // await the result.
        await axios.post('/api/send-message-on-mcm-assigning', {
          mcmEmail: mcm2Email,
          mcmName: mcm2Name,
        })
      } catch (error) {
        alert(
          'Something went wrong, sending confirmation email. Please contact us by email: info@enterprof.org',
        )
        console.log(error)
      }
      alert('Success!')
    } else {
      alert('Please select mcm member!')
    }
    setShowFinalSpinner(false)
  }
  const handleSubmitMCM3 = async (values) => {
    if (values.mcm3 !== 'Please Select') {
      setShowFinalSpinner(true)
      const userToBeReviewed = usersToBeReviewed.filter(
        (user) => user?.id === values.userToBeReviewedID,
      )[0]

      const mcm3Email = monitoring_committee_members.filter(
        (user) => user?.id === values.mcm3,
      )[0]?.email
      const mcm3Name = monitoring_committee_members.filter(
        (user) => user?.id === values.mcm3,
      )[0]?.full_name

      const allMCMs =
        (userToBeReviewed.mcm1 || '') +
        ', ' +
        (userToBeReviewed.mcm2 || '') +
        ', ' +
        values.mcm3

      try {
        firestore
          .collection('usersCollection')
          .doc(values.userToBeReviewedID)
          .update({
            dateAppliedForReviewMCM3: new Date().toLocaleString(), // 12/04/2022 19:12:27
            mcm3: values.mcm3,
            mcms: allMCMs,
            under_review: true,
          })
      } catch (err) {
        alert(
          'Something went wrong, on writing your data. Please contact us by email: info@enterprof.org',
        )
        console.log(err)
      }
      try {
        // Send data to the function,
        // await the result.
        await axios.post('/api/send-message-on-mcm-assigning', {
          mcmEmail: mcm3Email,
          mcmName: mcm3Name,
        })
      } catch (error) {
        alert(
          'Something went wrong, sending confirmation email. Please contact us by email: info@enterprof.org',
        )
        console.log(error)
      }
      alert('Success!')
    } else {
      alert('Please select mcm member!')
    }
    setShowFinalSpinner(false)
  }

  return (
    <div>
      {!!showFinalSpinner && <Spinner />}
      {usersToBeReviewed?.length === 0 && (
        <div>
          <h1>There are no Applications for register</h1>
          <div className="d-flex flex-row mt-2">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleClose}
              type="button"
            >
              Back
            </button>
          </div>
        </div>
      )}
      {usersToBeReviewed?.length !== 0 && (
        <div>
          <h1>List of Applications for register</h1>
          <div className="d-flex flex-row mt-2">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleClose}
              type="button"
            >
              Back
            </button>
          </div>
          {usersToBeReviewed.map((userToBeReviewed, key) => {
            const initialValuesMCM1 = {
              userToBeReviewedID: userToBeReviewed.id,
              mcm1: userToBeReviewed.mcm1 || 'Please Select',
            }
            const initialValuesMCM2 = {
              userToBeReviewedID: userToBeReviewed.id,
              mcm2: userToBeReviewed.mcm2 || 'Please Select',
            }
            const initialValuesMCM3 = {
              userToBeReviewedID: userToBeReviewed.id,
              mcm3: userToBeReviewed.mcm3 || 'Please Select',
            }

            return (
              <div
                key={key}
                className="border border-3 border-info rounded-3 p-3 mt-3 mb-5"
              >
                <span className="bg-warning rounded-3 p-2">
                  {userToBeReviewed?.full_name}
                </span>
                <br />
                <br />
                <span>ID: {userToBeReviewed.id}</span>
                {userToBeReviewed.applicantID && (
                  <span>
                    <br />
                    ApplicantID: {userToBeReviewed.applicantID}
                  </span>
                )}
                <br />
                <br />
                <Formik
                  enableReinitialize
                  initialValues={initialValuesMCM1}
                  isSubmitting={true}
                  onSubmit={handleSubmitMCM1}
                >
                  <Form className="">
                    <div>
                      <SelectMCM
                        name="mcm1"
                        label="Select Monitoring Committee Member > 1"
                      >
                        <option value="Please Select">Please Select</option>
                        {monitoring_committee_members.map((mcm, key) => {
                          return (
                            <option value={mcm.id} key={key}>
                              {mcm?.full_name}
                            </option>
                          )
                        })}
                        <option value="submitted">submitted</option>
                      </SelectMCM>
                      <button
                        className="btn btn-sm btn-outline-dark mt-2"
                        id="submit"
                        type="submit"
                      >
                        Send
                      </button>
                      <hr />
                    </div>
                  </Form>
                </Formik>
                <Formik
                  enableReinitialize
                  initialValues={initialValuesMCM2}
                  isSubmitting={true}
                  onSubmit={handleSubmitMCM2}
                >
                  <Form className="">
                    <div>
                      <SelectMCM
                        name="mcm2"
                        label="Select Monitoring Committee Member > 2"
                      >
                        <option value="Please Select">Please Select</option>

                        {monitoring_committee_members.map((mcm, key) => {
                          return (
                            <option value={mcm.id} key={key}>
                              {mcm?.full_name}
                            </option>
                          )
                        })}
                        <option value="submitted">submitted</option>
                      </SelectMCM>

                      <button
                        className="btn btn-sm btn-outline-dark mt-2"
                        id="submit"
                        type="submit"
                      >
                        Send
                      </button>
                      <hr />
                    </div>
                  </Form>
                </Formik>
                <Formik
                  enableReinitialize
                  initialValues={initialValuesMCM3}
                  isSubmitting={true}
                  onSubmit={handleSubmitMCM3}
                >
                  <Form className="">
                    <div>
                      <SelectMCM
                        name="mcm3"
                        label="Select Monitoring Committee Member > 3"
                      >
                        <option value="Please Select">Please Select</option>

                        {monitoring_committee_members.map((mcm, key) => {
                          return (
                            <option value={mcm.id} key={key}>
                              {mcm?.full_name}
                            </option>
                          )
                        })}
                        <option value="submitted">submitted</option>
                      </SelectMCM>
                      <button
                        className="btn btn-sm btn-outline-dark mt-2"
                        id="submit"
                        type="submit"
                      >
                        Send
                      </button>
                      <hr />
                    </div>
                  </Form>
                </Formik>
                <button
                  onClick={async () => {
                    try {
                      firestore
                        .collection('usersCollection')
                        .doc(userToBeReviewed.id)
                        .update({
                          to_be_reviewed: false,
                        })
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                  className={`btn w-50 ${
                    !userToBeReviewed.to_be_reviewed
                      ? 'btn-success'
                      : 'btn-secondary'
                  }`}
                >
                  Hide from here
                </button>
              </div>
            )
          })}
          <div className="d-flex flex-row mt-2">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleClose}
              type="button"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ListOfEducatorsForRegister
